import { useEffect } from "react";
import PostItemSubscribe from "./PostItemSubscribe";

function PostItem(props) {
    useEffect(() => {
        // Создаём iframe
        const iframe = document.createElement("iframe");
        iframe.async = true;
        iframe.src = `https://management.viglo.pro/api/parser/tg_widget/${encodeURIComponent(props.url)}`;
        iframe.id = `iframe_${props.url.replace("https://t.me/", "")}`;
        iframe.setAttribute("frameborder", "0");
        iframe.setAttribute("width", "100%");
        iframe.setAttribute("scrolling", "no");
        iframe.style.overflow = "hidden";
        iframe.style.border = "none";
        iframe.style.minWidth = "320px";
        iframe.style.colorScheme = "light dark";

        // const script_base = document.createElement("script")
        // script_base.async = true
        // script_base.src = "https://telegram.org/js/telegram-widget.js?22"
        // script_base.setAttribute("id", props.url.replace("https://t.me/", ""))
        // script_base.setAttribute("data-telegram-post", props.url.replace("https://t.me/", ""))
        // script_base.setAttribute("data-width", "100%")
        // script_base.setAttribute("data-userpic", "false")
        // script_base.setAttribute("data-dark", "0")
        // document.getElementById(props.url).appendChild(script_base)

        // console.log(document.querySelector("span").height())
        // const script = document.createElement('iframe');
        // script.setAttribute("data-telegram-post", props.url.replace("https://t.me/",""))
        // script.setAttribute("id", props.url)
        // script.setAttribute("src", props.url + "?embed=1&amp;dark=0")
        // script.setAttribute("width", "100%")
        // script.setAttribute("frameBorder", "0")
        // script.setAttribute("scrolling", "no")
        // script.setAttribute("style", "overflow: hidden; color-scheme: light; border: none; min-width: 320px;")
        // document.getElementById(props.url).appendChild(script)

        // Добавляем iframe в контейнер
        const container = document.getElementById(props.url);
        container.appendChild(iframe);

        // Обработчик сообщения для изменения высоты iframe
        const handleMessage = (event) => {
            // Проверяем, что сообщение приходит из нужного источника
            if (event.origin === "https://management.viglo.pro") {
                try {
                    const data = JSON.parse(event.data);

                    // Проверяем, что сообщение предназначено для текущего iframe
                    if (data.event === "resize" && data.height && data.id === iframe.id) {
                        iframe.style.height = `${data.height}px`;
                    }
                } catch (e) {
                    console.warn("Invalid message data:", e);
                }
            }
        };

        // Добавляем обработчик события message
        window.addEventListener("message", handleMessage);

        // Очищаем слушатели при размонтировании компонента
        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, [props.url]);

    return (
        <div>
            <div id={props.url}></div>
            <PostItemSubscribe url={props.url} />
        </div>
    );
}

export default PostItem;

// import {useEffect} from "react";
// import PostItemSubscribe from "./PostItemSubscribe";
//
// function PostItem(props) {
//
//     useEffect(() => {
//         const script_base = document.createElement("iframe")
//         script_base.async = true
//         script_base.src = "https://management.viglo.pro/api/parser/tg_widget/"+encodeURIComponent(props.url)
//         script_base.setAttribute("id", props.url.replace("https://t.me/", ""))
//         script_base.setAttribute("frameborder", "0")
//         script_base.setAttribute("width", "100%")
//         script_base.setAttribute("scrolling", "no")
//         script_base.setAttribute("style", "overflow: hidden; color-scheme: light; border: none; min-width: 320px;")
//         document.getElementById(props.url).appendChild(script_base)
//

//
//     }, []);
//
//     function setIframeHeight(id) {
//         const iframe = document.getElementById(id);
//         if (iframe.contentWindow.document.body) {
//             iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
//         }
//     }
//
//
//     return (<div>
//         <div id={props.url}>
//
//         </div>
//         <PostItemSubscribe url={props.url}/>
//     </div>)
//
// }
//
// export default PostItem