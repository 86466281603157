import {defaults} from "../config.js"

const defaultGlobal = defaults

const UPDATE_POSTS = "UPDATE_POSTS"


export const globalReducer = (state = defaultGlobal, action) => {
    switch (action.type) {
        case UPDATE_POSTS:
            return {...state,posts: action.payload}


        default:
            return state
    }
}